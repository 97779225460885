import React, { PureComponent } from 'react';
import IconUnview from '../../assets/icons/IconUnview';
import IconView from '../../assets/icons/IconView';

class PaswordInput extends PureComponent {
   state = {
      show: false,
   }

   toggle = () => {
      this.setState({
         show: !this.state.show,
      });
   }

   render() {
      return (
         <div className="wrapped-input base-input">
            <input type={this.state.show ? 'text' : 'password'}
               name={this.props.name}
               value={this.props.value}
               placeholder={this.props.placeholder}
               onChange={this.props.onChange}
               required={this.props.required}
            />
            <div className="square-button" onClick={this.toggle}>
               {
                  this.state.show ?
                     <IconView />
                     :
                     <IconUnview />
               }
            </div>
         </div>
      );
   }
}

export default PaswordInput;