import React, { PureComponent } from 'react';

class IconBack extends PureComponent {
   render() {
      return (
         <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            aria-hidden="true"
            role="img"
            className="iconify iconify--ic"
            width="20px"
            height="20px"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
         >
            <path
               d="M20 11H7.83l5.59-5.59L12 4l-8 8l8 8l1.41-1.41L7.83 13H20v-2z"
               fill="currentColor"
            />
         </svg>
      );
   }
}

export default IconBack;