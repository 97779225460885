import Link from 'next/link';
import React, { PureComponent } from 'react';
import IconBack from '../assets/icons/IconBack';
import { localeFunction } from '../locales/functions';

class MbStopPage extends PureComponent {
   render() {
      return (
         <div className="mb-stop-access-page">
            <span>
               {localeFunction.locale.stop_page_message}
            </span>
            <Link href="/">
               <a className="btn-back-home">
                  <IconBack /><span>{localeFunction.locale.back_to_home}</span>
               </a>
            </Link>
         </div>
      );
   }
}

export default MbStopPage;