import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import { adminApi } from '../../apis/AdminApi';
import IconBack from '../../assets/icons/IconBack';
import PaswordInput from '../../components_admin/Components/PaswordInput';
import MbStopPage from '../../components_admin/MbStopPage';
import { constants } from '../../utils/constants';

const Login = (props) => {
   const router = useRouter();
   const [identifier, setIdentifier] = useState('');
   const [password, setPassword] = useState('');
   const [error, setError] = useState('');


   const changeIdentifier = useCallback((e) => {
      setIdentifier(e.target.value);
      setError('');
   },
      []
   )

   const changePassword = useCallback((e) => {
      setPassword(e.target.value);
      setError('');
   },
      []
   )

   const submit = async () => {
      const response = await adminApi.login(identifier, password);
      if (response && response.data && response.data.token) {
         localStorage[constants.TOKEN_VARIABLE] = await response.data.token;
         router.push('/admin');
      } else {
         setError('Email or password invalid.');
      }
   }

   const keyDownSubmit = (e) => {
      if (e.keyCode == 13) {
         submit();
      }
   }

   return (
      <div className={`app ${props.isMobileView ? 'mobile-view' : ''}`}>
         <Head>
            <title>LOGIN | DNAP ARCHITECTURE</title>
            <link rel="icon" href="/favicon.ico" />
            <link href="/globals.css" rel="stylesheet" />
            <link
               rel="stylesheet"
               href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
            />
            <style>
               {`
						@font-face {
							font-family: 'Myriadpro';
							src: url('/fonts/MYRIADPRO/MYRIADPRO-LIGHT.OTF');
						}
						@font-face {
							font-family: 'Myriadpro-semic';
							src: url('/fonts/MYRIADPRO/MYRIADPRO-LIGHTSEMICN.OTF');
						}
					`}
            </style>
         </Head>

         <main className="main">
            {
               props.isMobileView &&
               <MbStopPage />
            }
            {
               !props.isMobileView &&
               <div className="login-page" onKeyDown={keyDownSubmit}>
                  <Link href="/">
                     <a className="btn-back-home">
                        <IconBack /><span>Back to home</span>
                     </a>
                  </Link>
                  <div className="login-container animate__animated animate__fadeInDown">
                     <label>ADMINISTRATOR LOGIN</label>
                     <div className={`error-box animate__animated ${error ? 'show ' : ''} `}>{error}</div>
                     <label className="field-title">Email <span className="required">*</span></label>
                     <input className="base-input" type="email"
                        placeholder="Email"
                        value={identifier}
                        onChange={changeIdentifier}
                        required
                     />
                     <label className="field-title">Password <span className="required">*</span></label>
                     <PaswordInput
                        placeholder="Password"
                        name="password"
                        value={password}
                        onChange={changePassword}
                        required
                     />
                     <button className="base-button active" onClick={submit}
                        disabled={!identifier.trim() || !password.trim()}
                     >
                        LOGIN
                  </button>
                  </div>
               </div>
            }
         </main>
      </div>
   )
}

export async function getServerSideProps({ req }) {
   let isMobileView = (req
      ? req.headers['user-agent']
      : navigator.userAgent).match(
         /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      );

   return {
      props: {
         isMobileView: Boolean(isMobileView),
      }
   }
}

export default Login;
