import React, { PureComponent } from 'react';

class IconView extends PureComponent {
   render() {
      return (
         <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
         >
            <path
               d="M0.5 8C0.5 8 3.5 2 8 2C12.5 2 15.5 8 15.5 8C15.5 8 12.5 14 8 14C3.5 14 0.5 8 0.5 8Z"
               stroke="#65627A"
               strokeMiterlimit={10}
               strokeLinecap="square"
               strokeLinejoin="round"
            />
            <path
               d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
               stroke="#65627A"
               strokeMiterlimit={10}
               strokeLinecap="square"
            />
         </svg>
      );
   }
}

export default IconView;